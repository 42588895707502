import React, { useState } from "react";
import { Icon } from "@cochlear-design-system/foundation/dist/esm/Elements/Icon/Icon";
import Modal from "@cochlear-design-system/foundation/dist/esm/Compositions/Modal/Modal";

// different from container - no -md, apply to all sizes
const getHorizontalAlign = (horizontalAlign) => {
  return `justify-content-${horizontalAlign || "center"}`;
};

const GenericImage = ({ fields }) => {
  const [showModal, setShowModal] = useState(false);

  /**
   * Generate a DAM image url with a transformation to the required width
   * @param {number} width Supported values from DAM are 20, 480, 800 or 1200
   */
  const generateRenditionUrl = (renditionWidth) => {
    const url = new URL(fields.image.value.src);
    url.searchParams.append("t", `w${renditionWidth}`);
    return url.toString();
  };

  const curveClassName = (() => {
    let _curveClassName = "";

    switch (fields.curveCorner?.name) {
      case "top left":
        _curveClassName = "top-left";
        break;
      case "top right":
        _curveClassName = "top-right";
        break;
      case "bottom left":
        _curveClassName = "bottom-left";
        break;
      case "bottom right":
        _curveClassName = "bottom-right";
    }

    if (_curveClassName && fields.largeCurve?.value) {
      _curveClassName += " large-curve";
    }

    return _curveClassName;
  })();

  return (
    <div
      className={`generic-image ${getHorizontalAlign(
        fields.horizontalAlign?.name,
      )} ${fields["css classes"]?.value}`}
      style={{
        height: fields.fillHeight?.value
          ? "100%"
          : fields.height?.value
            ? `${fields.height.value}px`
            : undefined,
      }}
    >
      <div
        className={`image-container ${
          fields.fillMode?.name === "fit" ? "fit" : "fill"
        }`}
      >
        <img
          alt={fields.image.value.alt}
          className={`${fields.roundedCorners?.value ? "rounded-corners" : ""}${
            fields.rtlFlip?.value ? " rtl-flip " : ""
          }${curveClassName}`}
          height={fields.image.value.height}
          loading="lazy"
          sizes={"(max-width: 767px) 480px, (max-width: 1023px) 800px, 1200px"}
          srcSet={`${generateRenditionUrl(480)} 480w, ${generateRenditionUrl(
            800,
          )} 800w, ${generateRenditionUrl(1200)} 1200w`}
          src={generateRenditionUrl(1200)}
          style={
            fields.disableBlur?.value
              ? undefined
              : {
                  backgroundImage: `url("${generateRenditionUrl(20)}")`,
                  // inline to avoid waiting for stylesheet to load
                  backgroundSize:
                    fields.fillMode?.name === "fit" ? "contain" : "cover",
                }
          }
          width={fields.image.value.width}
        />

        {fields.expandable?.value && (
          <button
            onClick={() => setShowModal(true)}
            type="button"
            className="expand-button"
          >
            <Icon type="north-east" />
          </button>
        )}
      </div>

      <Modal
        centred
        fullscreen
        onHide={() => setShowModal(false)}
        show={showModal}
      >
        <Modal.Header closeButton>
          {/* empty div needed to force close button to right */}
          <div />
        </Modal.Header>

        <Modal.Body>
          <div className="generic-image-modal-body">
            <img
              alt={fields.image.value.alt}
              className={fields.rtlFlip?.value ? "rtl-flip" : undefined}
              height={fields.image.value.height}
              loading="lazy"
              src={fields.image.value.src}
              width={fields.image.value.width}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GenericImage;
